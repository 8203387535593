
html{
  margin:0;
  padding:0;
}
body{
  background: #fff;
}
.imageBlock{
  width:100%;
  height: auto;
  margin: 0 auto;
  background: #fff;
  // display: flex;
  // flex-wrap:wrap;
  // align-items: center;
  // justify-content: space-between;
}
.pracycontent{
  width: 90%;
  margin: 0 auto;
  height: auto;
}
.paacytitle{
  font-size: 28px;
  text-align: center;
  padding-top: 50px;
}
.paravylist{
  font-size: 16px;
  text-align: justify;
  padding-top:20px;
  line-height: 1.5;
}






