
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.recruit{
  width:100%;
  height: auto;
  margin: 0 auto;
  max-width: 980px;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  background:#ededed;
}
.recruit img{
  width: 100%;
  // max-width: 980px;
  // margin: 0 auto;
}
.recruitcontent{
   width: 100%;
   height: 120px;
   background: #fff;
   margin-bottom: 10px;
   padding: 30px 20px;
}
.firstline{
  width: 100%;
  height: auto;
}
.secondline{
  width: 100%;
  height: auto;
  margin-top: 25px;
  display: flex;
}
.secondline span{
  display: inline-block;
  background: #F5F5F5;
  padding: 10px;
  border-radius: 5px;
  color: #808080;
  font-weight: bold;
}
.thirdline{
  width: 100%;
  height: auto;
  display: flex;
  margin-top: 25px;
  position: relative;
  // font-size: 18px;
  font-weight: bold;
  color: #4D4D4D;
}
.thirdline img{
  width: 16px;
  height: 16px;
}
.thirdline .address{
  position: absolute;
  right: 0;
  color: #B3B3B3;
}


