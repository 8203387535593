.adm-mask {
  // :global {
  //   .adm-mask {
  //     --z-index: var(--adm-mask-z-index, 1000);
  //     position: fixed;
  //     top: 0;
  //     left: 0;
  //     z-index: var(--z-index);
  //     display: block;
  //     width: 100%;
  //     height: 100%;
  //     background: white !important;
  //   }
  //   .adm-image-viewer-indicator {
  //     position: absolute;
  //     z-index: 2;
  //     left: 50%;
  //     top: 12px;
  //     transform: translateX(-50%);
  //     color: #0f0e0e;
  //     font-size: 14px;
  //   }
    // .adm-image-viewer-image-wrapper img {
    //   display: block;
    //   width: 100%;
    //   height: 100%;
    // }
 // }
     --z-index: var(--adm-mask-z-index, 1000);
      position: fixed;
      top: 0;
      left: 0;
      z-index: var(--z-index);
      display: block;
      width: 100%;
      height: 100%;
      background: white !important;
}

.adm-mask .adm-image-viewer-indicator {
      position: absolute;
      z-index: 2;
      left: 50%;
      top: 12px;
      transform: translateX(-50%);
      color: #0f0e0e;
      font-size: 14px;
    }
