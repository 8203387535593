
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.imageBlock{
  width:100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
}
.imageBlock img{
  width: 100%;
}

.bottomLink-ddsu5168{
  position: relative;
  font-size: 28px;
  font-weight: bold;
  // position: relative;
  // bottom: 390px;
  // width: 82%;
  // text-align: end;
  // margin: 0 auto;
  // font-size: 28px;
  // font-weight: bold;
}
.bottomLink-ddsu5168 div{
  position: absolute;
  right: 9%;
  top: 60%;
  font-size: 50%;
}
.bottomLink-ddsu5168 a{
  color:#204098;
}


