
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.imageBlock{
  width:100%;
  /* // height: auto; */
  margin: 0 auto;
  max-width: 980px;
  /* // display: flex;
  // flex-wrap:wrap;
  // align-items: center;
  // justify-content: space-between; */
}
.imageBlock img{
  width: 100%;
  height: auto;
}

.container{
  // background-image:url('../../assets/indexpage/indexbg.png');
  // background-repeat: no-repeat;
  // // background-size:cover;
  // width: 100%;
  // height: 700px;
  // overflow: hidden;

  position: relative;
}
   
 .container .innerproduct img{
   position: absolute;
   top:0;
   left:0;
   width: 50%;

  }

.container .innersolution img{
  position: absolute;
  width: 50%;
  // bottom: 0;
  // right: 0;
  left: 50%;
  top:49.6%
}





