
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.mainContent{
  width:100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.imageInfo{
  position: relative;
  font-size: 14px;
}

.imageInfo img{
  width: 100%;
}


.adm-tag {
  padding: 2px 4px;
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  white-space: nowrap;
  border-radius: 4px;
  border: 0.5px solid white;
  color: white;
  background: transparent;
  box-sizing: border-box;
  &-round {
    border-radius: 100px;
  }
  &-outline {
    background: #e6f7ff;
    color: #1890ff;
  }
}