
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.elecIndex{
  max-width: 980px;
  width:90%;
  height: auto;
  margin: 20px auto;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  background: #f6f6f6;
}

.elecIndex div{
  width: 48%;
}
.elecIndex img{
  width: 100%;
  margin-bottom: 20px;
}


