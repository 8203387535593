// @import "../../../assets/style/scss/common.scss";

html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
p{
  margin: 0;
  padding: 0;
}
.contentbg{
  // background: #ff33cc;
  max-width: 980px;
  margin: 0 auto;
}


.contentproductbg{
  width: 100%;
  height:auto;
}
.contentproductbg img {
  width: 100%;
  height: 100%;
}

.productlist{
  width: 100%;
  // height: 380px;
  background: #fff;
  display: flex;
  flex-wrap:wrap;
  padding-top:25px;
}

.listitem{
  width: 25%;
  height:110px;
  display: flex;
  align-items: center;
  flex-direction:column;
}
.listitem img{
  width: 50px;
  height: 50px;
}
.listitem p{
  font-size: 14px;
  font-weight: bold;
  color: #333;
  text-align: center;
  padding-top: 8px;
}
.producttitle{
  width: 50%;
  height: 100px;
  margin:0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.titleleft{
  width: 25%;
  height: 2px;
  background: #1D273E;
}
.titletext{
  font-size: 18px;
  font-weight: bold;
  padding: 0 10px 0 10px;
  color: #1D273E;
}
.titleright{
  width: 25%;
  height: 2px;
  background: #1D273E;
}
.classcalproduct{
  width: 90%;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  background: #f6f6f6;
}
.classcalproduct div{
  width: 48%;
  margin-bottom: 20px;
}
.classcalproduct img{
   width: 100%;
}