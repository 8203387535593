
html{
  margin:0;
  padding:0;
  background: #fff;
}
body{
  background: #fff;
}

.titlecontent{
  width:100%;
  height:auto;
  background: #fff;
  max-width: 980px;
  margin: 0 auto;
}
.titlefirst{
  width: 90%;
  margin: 40px auto;
  margin-bottom: 0;
}
.jobtitleleft{
  font-size: 22px;
  font-weight: bold;
}
.jobtitleright{
  color: #224273;
  // font-size: 22px;
  font-weight: bold;
  float: right;
}
.titlesecond{
  width: 90%;
  height: auto;
  margin: 20px auto;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #DEDEDE;
  padding-bottom: 40px;
}
.titlesecond img{
  width: 16px;
  height: 16px;
}
.titlesecond span{
  font-size: 16px;
}
.jobtitle{
  width: 90%;
  margin:20px auto;
  font-size: 20px;
  font-weight: bold;
}
.joblist{
  width: 90%;
  margin:10px auto;
  font-size: 18px;
  line-height: 1.5;
  color: #666666;
}
.jobneed{
  width:90%;
  height: auto;
  margin: 16px auto;
  color: #666666;
  font-size: 18px;
  margin-bottom:10px;
}


