
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.gatewayIndex{
  width:90%;
  height: auto;
  margin: 20px auto;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
}

.gatewayIndex div{
  width: 48%;
}
.gatewayIndex img{
  width: 100%;
}


