
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.imageBlock{
  width:100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
}
.imageBlock img{
  width: 100%;
}


.bottomLink-s663{
  position: relative;
  font-size: 28px;
  font-weight: bold;
  // bottom: 660px;
  // right:84px;
  // width: 82%;
  // text-align: end;
  // margin: 0 auto;
  // border: 5px solid #f3c;
  // background: #fc3;
}
.bottomLink-s663 div{
   position: absolute;
   right: 9%;
   top: 46%;
  //  bottom: 46%;
   font-size: 50%;
  //  background: #f3c;
}
.bottomLink-s663 a{
  color:#204098;
}

