
body{
  // background: rgb(246,245,248);
  margin:0;
  padding: 0;
}

.meterIndex{
  width:90%;
  height: auto;
  margin: 0 auto;
  max-width: 980px;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  
  background: #f6f6f6;
}

.meterIndex div{
  width: 48%;
  margin-bottom: 10px;
}
.meterIndex img{
  width: 100%;
  margin-top: 10px;
}


