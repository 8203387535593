
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.imageBlock{
  width:100%;
  height: auto;
  margin: 0 auto;
  background: #fff; 
  // display: flex;
  // flex-wrap:wrap;
  // align-items: center;
  // justify-content: space-between;
}

.content{
  background-image:url('../../../../assets/wxhome/indexbg.png');
  background-repeat: no-repeat;
  background-size:cover;
  width: 100%;
  height: 700px;
}
   
.content img{
  width: 50%;
  height: 350px;
}





