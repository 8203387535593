
html{
  margin:0;
  padding:0;
}

.solutionHome{
  width:100%;
  height: auto;
  max-width: 980px;
  margin: 0 auto;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  background: #f3c;
  
}
.solutionHome img{
  width: 100%;
}
.list{
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
}
.list img{
  width: 90%;
  margin:15px auto;
}


