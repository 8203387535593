
html{
  margin:0;
  padding:0;
}
body{
  background: rgb(246,245,248);
}
.imageBlock{
  width:100%;
  height: auto;
  margin: 0 auto;
  display: flex;
  flex-wrap:wrap;
  align-items: center;
  justify-content: space-between;
}
.imageBlock img{
  width: 100%;
}

.bottombutton1{
  position: relative;
  bottom: 410px;
  width: 82%;
  text-align: end;
  margin: 0 auto;
  font-size: 28px;
  font-weight: bold;
}
.bottombutton1 a{
  color:#204098;
}




