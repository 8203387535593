.adm-index-bar {
  --color: #333333;
  overflow: hidden;
  height: 100%;
  position: relative;
  background-color: #ffffff;
  --sticy-offset-top: 0;

  &-body {
    overflow: scroll;
    height: 100%;
    width: 68%;
    float: right;
    &::-webkit-scrollbar {
      display: none;
    }
  }

  &-anchor {
    &-title {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 35px;
      padding: 0 12px;
      color: #999999;
      font-size: 13px;
      background-color: #ffffff;
    }
  }

  &-sidebar {
    // top: 30%;
    float: left;
    position: block;
    width: 30%;
    height: 100%;
    z-index: 3;
    overflow: visible;
    color: #999999;
    font-size: 12px;
    user-select: none;
    touch-action: none;    
    background-color: #f2f2f2; 

    &-row {
      cursor: pointer;
      width: 100%;
      text-align: center;
      color: #000;
    }

    &-item {
      display: inline-flex;
      padding: 10px 0px;
      width: 100%;
      font-size: 10;
      justify-content: center;
      align-items: center;
      color: #000;
      background-color: #f2f2f2;
      border-radius: 0px;
    }

    &-item-active {     
      color: #000;
      font-weight: 600;
      background-color: #fff;
      padding-left: 2px;
      position: relative;    
    }

  }

  &-sticky {
    .adm-index-bar-anchor-title {
      position: sticky;
      top: var(--sticy-offset-top);
      left: 0;
    }
  }

  &-border {
    background-color: #fff;
    width: 10px;
    height: 10px;
    position: absolute;
    right: 0;    
    
    &:before {
      content: '';
      width: 10px;
      height: 10px;
      background-color: #f2f2f2;
      position: absolute;
      
      z-index: 0;
    }
    
    &.border-top {
      top: -10px;
      
      &:before {
        border-radius: 0 0 10px 0;
        right: 0;   
      }
    }
    
    &.border-bottom {
      bottom: -10px;
      
      &:before {
        border-radius: 0 10px 0 0;
        right: 0;   
      }
    }
  }
}



.adm-selector {
  --color: gray;
  --checked-color: #e7f1ff;

  overflow: hidden;
  font-size: 10px;
  line-height: 1.4;

  &-item {
    padding: 4px 16px;
    position: relative;
    background-color: #f5f5f5;
    border-radius: 16px;
    color: gray;
    opacity: 1;
    cursor: pointer;
    display: inline-block;
    text-align: center;
    overflow: hidden;
    vertical-align: top;
    margin: 4px;
    font-size: 10px;

    &-active,
    &-multiple-active {
      color: #1677ff;
      background-color: var(--checked-color);
    }

    .adm-selector-check-mark-wrapper {
      position: absolute;
      right: 0;
      bottom: 0;
      width: 0;
      height: 0;
      border-top: solid 8px transparent;
      border-bottom: solid 8px #1677ff;
      border-left: solid 10px transparent;
      border-right: solid 10px #1677ff;
      > img {
        position: absolute;
        left: 0;
        top: 0;
        height: 6px;
        width: 8px;
      }
    }

    &-disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
}

.adm-tag {
  padding: 2px 4px;
  font-size: 10px;
  font-weight: normal;
  display: inline-block;
  white-space: nowrap;
  border-radius: 4px;
  border: 0.5px solid #91d5ff;
  color: #1890ff;
  background: #e6f7ff;
  box-sizing: border-box;
  &-round {
    border-radius: 100px;
  }
  &-outline {
    background: #e6f7ff;
    color: #1890ff;
  }
}

.adm-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 24px 0;

  &-image-container {
    display: flex;
    justify-content: center;
    &-image {
      width: 64px;
    }
  }

  &-description {
    margin-top: 8px;
    font-size: 14px;
    color: #cccccc;
  }
}

.adm-loading {
  display: inline-block;
}

.list-body {
  flex: 1 1;
  color: #000;
  font-size: 17px;
  line-height: 1.5;
  text-align: left;
  width: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 4px 0px;
  border: 0
}

.selector-item-title {
  font-size: 12px;
  font-weight: 600;
  margin-left: 8px;
}

.item-title {
  font-size: 10px;
  color: grey;
}

.item-data {
  font-size: 12px;
  font-weight: 400;
  color: black;
}

.detail-title {
  font-size: 10px;
  color: grey;
}

.detail-data {
  font-size: 12px;
  font-weight: 400;
  color: black;
}

.button-reset {
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding: 0px 15px;
  text-align: center;
  background-color: black;
  border-radius: 15px 0px 0px 15px;
  color: white;
}

.button-ok {
  display: block;
  height: 30px;
  line-height: 30px;
  font-size: 14px;
  padding: 0px 15px;
  text-align: center;
  background-color: #1677ff;
  border-radius: 0px 18px 18px 0px;
  color: white;
}

.button-prefix {
  position: absolute;
  top: 68%;
  z-index: 3;
  display: flex;
  align-items: center;
  color: grey;
  line-height: 0;
  transform: translateY(-50%);
  padding: 0 16px;
}

.input {
  box-sizing: border-box;
  margin: 0px 8px;
  font-variant: tabular-nums;
  list-style: none;
  font-feature-settings: "tnum";
  position: relative;
  display: inline-block;
  width: 80%;
  height: 32px;
  padding: 4px 34px;
  color: rgba(0,0,0,.65);
  font-size: 14px;
  line-height: 1.5;
  background-color: #fff;
  background-image: none;
  border: 1px solid #f2f2f2;
  border-radius: 4px;
  transition: all .3s;
  outline: 0;
  -webkit-appearance: none;
}

.input:focus, input:hover {
  border-color: #40a9ff;
  border-right-width: 1px !important;
  
}

.input::-moz-placeholder {
  color: #bfbfbf;
  opacity: 1;
}
.input:-ms-input-placeholder {
  color: #bfbfbf;
}
.input::-webkit-input-placeholder {
  color: #bfbfbf;
}

.adm-popover {
  &-light {
    --popover-bg: #ffffff;
  }

  &-dark {
    --popover-bg: rgba(0, 0, 0, 0.75);
    --adm-color-text: #ffffff;
    color: white;
  }

  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--adm-z-index-popover);
  font-weight: normal;
  white-space: normal;
  text-align: left;
  cursor: auto;
  user-select: text;
  animation: none;

  &::after {
    position: absolute;
    background: fade(#ffffff, 1%);
    content: '';
  }
  &-hidden {
    display: none;
  }

  // Offset the popover to account for the popover arrow
  &-placement-top,
  &-placement-topLeft,
  &-placement-topRight {
    padding-bottom: 6px;
  }

  &-placement-right,
  &-placement-rightTop,
  &-placement-rightBottom {
    padding-left: 6px;
  }

  &-placement-bottom,
  &-placement-bottomLeft,
  &-placement-bottomRight {
    padding-top: 6px;
  }

  &-placement-left,
  &-placement-leftTop,
  &-placement-leftBottom {
    padding-right: 6px;
  }

  &-inner {
    background-color: var(--popover-bg);
    background-clip: padding-box;
    border-radius: 4px;
    box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
    min-width: 32px;
    min-height: 32px;
    overflow-y: hidden;
    &-content {
      padding: 12px;
    }

    &-menu {
      min-width: 120px;
      display: flex;
      justify-content: center;
      align-items: center;
      &-with-icon {
        min-width: 140px;
      }
      &-icon {
        margin-right: 12px;
        font-size: 20px;
        line-height: 1;
        vertical-align: -0.075em;
      }
      cursor: pointer;
      border-bottom: 1px solid var(--adm-border-color);

      &:last-child {
        border-bottom: none;
      }

      & button {
        border-radius: 0;
        padding: 10px 12px;
        text-align: left;
      }
    }
  }

  &-menu {
    .adm-popover-inner {
      &-content {
        padding: 0;
      }
    }
  }

  @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    /* IE10+ */
    &-inner {
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
  0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);;
    }
  }

  // Arrows
  // .popover-arrow is outer, .popover-arrow:after is inner

  &-arrow {
    position: absolute;
    display: block;
    width: sqrt(6 * 6 * 2);
    height: sqrt(6 * 6 * 2);
    background: transparent;
    border-style: solid;
    border-width: (sqrt(6 * 6 * 2) / 2);
    transform: rotate(45deg);
  }

  &-placement-top > &-content > &-arrow,
  &-placement-topLeft > &-content > &-arrow,
  &-placement-topRight > &-content > &-arrow {
    bottom: 6px - 6px + 3px;
    border-top-color: transparent;
    border-right-color: var(--popover-bg);
    border-bottom-color: var(--popover-bg);
    border-left-color: transparent;
    box-shadow: 3px 3px 7px fade(#000000, 7%);
  }
  &-placement-top > &-content > &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &-placement-topLeft > &-content > &-arrow {
    left: 16px;
  }
  &-placement-topRight > &-content > &-arrow {
    right: 16px;
  }

  &-placement-right > &-content > &-arrow,
  &-placement-rightTop > &-content > &-arrow,
  &-placement-rightBottom > &-content > &-arrow {
    left: 6px - 6px + 3px;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: var(--popover-bg);
    border-left-color: var(--popover-bg);
    box-shadow: -3px 3px 7px fade(#000000, 7%);
  }
  &-placement-right > &-content > &-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  &-placement-rightTop > &-content > &-arrow {
    top: 12px;
  }
  &-placement-rightBottom > &-content > &-arrow {
    bottom: 12px;
  }

  &-placement-bottom > &-content > &-arrow,
  &-placement-bottomLeft > &-content > &-arrow,
  &-placement-bottomRight > &-content > &-arrow {
    top: 6px - 6px + 3px;
    border-top-color: var(--popover-bg);
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: var(--popover-bg);
    box-shadow: -2px -2px 5px fade(#000000, 6%);
  }
  &-placement-bottom > &-content > &-arrow {
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  &-placement-bottomLeft > &-content > &-arrow {
    left: 16px;
  }
  &-placement-bottomRight > &-content > &-arrow {
    right: 16px;
  }

  &-placement-left > &-content > &-arrow,
  &-placement-leftTop > &-content > &-arrow,
  &-placement-leftBottom > &-content > &-arrow {
    right: 6px - 6px + 3px;
    border-top-color: var(--popover-bg);
    border-right-color: var(--popover-bg);
    border-bottom-color: transparent;
    border-left-color: transparent;
    box-shadow: 3px -3px 7px fade(#000000, 7%);
  }
  &-placement-left > &-content > &-arrow {
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  &-placement-leftTop > &-content > &-arrow {
    top: 12px;
  }
  &-placement-leftBottom > &-content > &-arrow {
    bottom: 12px;
  }
}

:global {
  .am-list-content {
    padding-top: 2px;
    padding-bottom: 2px;
  }
}
